<template>
  <el-dialog
    class="auth-dialog"
    width="450px"
    :title="$t('form.name.login')"
    :visible.sync="isVisible"
    :before-close="onClose"
  >
    <div v-if="message" class="mb-2">{{ message }}</div>

    <LoginCard @auth:success="setVisibility" >
      <template slot-scope="{ isLoading }">
        <el-button @click="onClose">
          {{ $t('buttons.exit') }}
        </el-button>

        <el-button
          type="primary"
          native-type="submit"
          :loading="isLoading"
        >
          {{ $t('buttons.login') }}
        </el-button>
      </template>
    </LoginCard>
  </el-dialog>
</template>

<script>
import { eventBus } from '@/services'
import LoginCard from './LoginForm'

export default {
  name: 'AuthDialog',

  components: {
    LoginCard,
  },

  data: () => ({
    isVisible: false,
    message: '',
  }),

  created() {
    eventBus.$on('isLoginPopup', this.setVisibility)
  },

  beforeDestroy() {
    eventBus.$off('isLoginPopup', this.setVisibility)
  },

  methods: {
    setVisibility({ value, message } = {}) {
      this.message = message || ''
      this.isVisible = !!value
    },

    onClose() {
      this.setVisibility()
      this.$store.dispatch('auth/logout')

      this.$router.push({
        name: 'login',
        query: { redirect: this.$route.path },
      })
    },
  },
}
</script>
